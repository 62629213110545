<template>
    <el-dialog :close-on-click-modal="false" title="新增设备项" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" :model="value" label-width="80px">

            <el-form-item label="设备分组">
                <el-select v-model="valueSEL" placeholder="请选择设备分组">
                    <el-option v-for="item in options1" :key="item.valueKEY" :label="item.label" :value="item.valueKEY">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="医院名称">
                <el-select v-model="valueSEL1" placeholder="请选择所属医院名称">
                    <el-option v-for="item in options1" :key="item.valueKEY" :label="item.label" :value="item.valueKEY">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="设备单位" prop="title">
                <el-input v-model="value.title" placeholder="请输入设备单位" />
            </el-form-item>

            <el-form-item label="设备号" prop="title">
                <el-input v-model="value.title" placeholder="请输入设备号" />
            </el-form-item>


            <el-form-item label="设备说明" prop="title">
                <el-input autosize type="textarea" :rows="2" placeholder="请输入设备说明" v-model="value.info" />
            </el-form-item>


            <el-form-item label="状态" prop="name2">
                <el-switch v-model="state" active-color="#5BD995" active-text="正常" inactive-color="#D9D9D9"
                    inactive-text="禁用" class="switchSelfBox">
                </el-switch>
            </el-form-item>

        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,


                state: true,

                radio: '1',


                options: [{
                    valueKEY: '选项1',
                    label: '天津南开卫健委'
                }, {
                    valueKEY: '选项2',
                    label: '天津西青卫健委'
                }, ],
                valueSEL: '',

                options1: [{
                    valueKEY: '选项1',
                    label: '天津南开卫健委'
                }, {
                    valueKEY: '选项2',
                    label: '天津西青卫健委'
                }, ],
                valueSEL1: '',

            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },


        }
    }
</script>

<style>
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择设备分组"],
  .selfInputBoxIV .el-input__inner[placeholder="请选择所属医院名称"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */

    .selfInputBoxIV .el-input__suffix {
        /*    border: 1px red solid !important;
    box-sizing: content-box !important; */
        right: -10px;
    }

    .selfInputBoxIV textarea {
        /* max-width: 300px;
    padding: 3px 10px; */
        width: calc(100% - 82px) !important;
        height: 60px !important;
        position: relative;
        left: 20px;

        /* border: 1px red solid !important; */
        box-sizing: content-box !important;
    }
</style>