<template>
	<div class="level_index level_indexI">
		<el-tabs v-model="activeName" @tab-click="handleClick">



			<el-tab-pane label="医废大箱子" name="001">
				<DeviceTab :activeName="activeName" v-if="activeName=='001'" />
			</el-tab-pane>
			<el-tab-pane label="手持终端PDA" name="002">
				<DeviceTab :activeName="activeName" v-if="activeName=='002'" />
			</el-tab-pane>
			<el-tab-pane label="手提医废箱子" name="003">
				<DeviceTab :activeName="activeName" v-if="activeName=='003'" />
			</el-tab-pane>
			<el-tab-pane label="蓝牙平称" name="004">
				<DeviceTab :activeName="activeName" v-if="activeName=='004'" />
			</el-tab-pane>
			<el-tab-pane label="蓝牙钩秤" name="005">
				<DeviceTab :activeName="activeName" v-if="activeName=='005'" />
			</el-tab-pane>
			<el-tab-pane label="地秤" name="006">
				<DeviceTab :activeName="activeName" v-if="activeName=='006'" />
			</el-tab-pane>
			<el-tab-pane label="医废中箱子" name="007">
				<DeviceTab :activeName="activeName" v-if="activeName=='007'" />
			</el-tab-pane>
			<el-tab-pane label="小米壁挂电视" name="008">
				<DeviceTab :activeName="activeName" v-if="activeName=='008'" />
			</el-tab-pane>
			<el-tab-pane label="台式电脑" name="009">
				<DeviceTab :activeName="activeName" v-if="activeName=='009'" />
			</el-tab-pane>
			<el-tab-pane label="无线流量路由器" name="010">
				<DeviceTab :activeName="activeName" v-if="activeName=='010'" />
			</el-tab-pane>
			<el-tab-pane label="无线路由器" name="011">
				<DeviceTab :activeName="activeName" v-if="activeName=='011'" />
			</el-tab-pane>

		</el-tabs>

	</div>
</template>

<script>
	import DeviceTab from './DeviceTab'


	export default {
		components: {
			DeviceTab
		},
		data() {
			return {

				activeName: '001',


			}
		},

		methods: {

			handleClick(tab, event) {
				console.log(tab.index)
			},



		}

	}
</script>

<style lang="scss" scoped>
	// .el-button--success {
	// 	color: #ffffff;
	// 	background-color: #5BD995;
	// 	border-color: #5BD995;
	// }
</style>

<style scoped>
	/* 	.level_indexI {
		border: 1px blue dotted;
		box-sizing: border-box;
	} */
	/*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
	/*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

	/*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */
</style>
